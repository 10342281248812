import React, { useRef, useState, useCallback, useEffect } from "react";
import prettyBytes from "pretty-bytes";

function ProductPhotosField(props) {
  const fileInputRef = useRef();
  const [tooManyFiles, setTooManyFiles] = useState(false);
  const [files, setFiles] = useState([]);

  const handleButtonClick = (e) => {
    fileInputRef.current.click();
  };

  const handleFileAdd = (filesToAdd) => {
    // Convert FileList to array
    filesToAdd = Array.from(filesToAdd);

    // Create a set to store unique file paths
    const fileNames = new Set(files.map((file) => file.name));

    // Filter out filesToAdd that have paths already present in the files array
    const uniqueFilesToAdd = filesToAdd.filter(
      (file) => !fileNames.has(file.name)
    );

    // Check if adding more files will exceed the maximum allowed
    if (files.length + uniqueFilesToAdd.length > 24) {
      setTooManyFiles(true);
    } else {
      setTooManyFiles(false);
      setFiles([...files, ...uniqueFilesToAdd]);
    }
  };

  const removeFile = (fileToBeRemoved) => {
    setFiles(files.filter((file) => file.name !== fileToBeRemoved.name));
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleFileAdd(acceptedFiles);
  }, []);

  useEffect(() => {
    props.onChange(files);
  }, [files]);

  return (
    <div className="product-photos-container">
      <div
        tabIndex={-1}
        className={`product-photos-dropzone ${
          files.length === 0 ? "" : "product-photos-files"
        }`}
        onClick={files.length === 0 ? handleButtonClick : undefined}
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {/* IF NO FILES ARE CURRENTLY HELD */}
        {files.length === 0 && (
          <>
            <span className="text-xs leading-5 text-gray-500">
              PNG, JPG, GIF til 10MB
            </span>
            <span className="product-photos-button">
            Last opp fil eller dra og slipp
            </span>
          </>
        )}

        {/* IF ANY FILE IS CURRENTLY HELD */}
        {files.length !== 0 && (
          <section>
          <div className="product-photos-files">
            {files.map((file) => (
              <div key={file.name} className="product-photos-file-container">
                <button
                  className="product-photos-remove-button"
                  onClick={() => removeFile(file)}
                >
                   x
                </button>
                <span className="product-photos-size-indicator">
                  {prettyBytes(file.size)}
                </span>    
                  <img
                    className="product-photos-image"
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                  />
              </div>
            ))}
          </div>
          
        </section>
        )}

        {/* Display error message if too many files */}
        {tooManyFiles && (
          <div className="product-photos-button-container">
            <span className="product-photos-button-label">
              For mange filer.
            </span>
            <span className="product-photos-button" onClick={handleButtonClick}>
              Last opp fil
            </span>
          </div>
        )}

        <input
          ref={fileInputRef}
          onChange={(e) => handleFileAdd(e.target.files)}
          id="files-upload"
          name="files-upload"
          type="file"
          className="sr-only"
          multiple
        />
      </div>
        <div>
            <div className="product-photos-info">
              <div className="product-photos-info-left">
                <span>{`Files: ${files.length} `}</span>
                <span>&nbsp;</span>
                <span>{`Total size: ${prettyBytes(
                  files.reduce((acc, file) => acc + file.size, 0)
                )}`}</span>
              </div>
              <button
                type="button"
                className="product-photos-info-button"
                onClick={handleButtonClick}
              >
                Last opp fil
              </button>
          </div>
        </div>
      
    </div>
  );
}

export default ProductPhotosField;