import call from '../assets/desktop/call-footer.png';
import transportation from '../assets/desktop/emoji_transportation.png';
import location from '../assets/desktop/location-footer.png';
import pdrLogo from '../assets/desktop/pdr-logo-footer.png';
import mail from '../assets/desktop/mail-footer.png';
import vector from '../assets/desktop/Vector1.png';
import {Link } from "react-router-dom";
export default function Footer(){
    return(
        <footer>
            <section className='row' id='middle-mobile'>
                <div className='column' id='container-row'>
                    <div className='left-footer'><img className='image-footer' src={pdrLogo}></img></div>
                    <div className='right-footer'>
                        <Link to={'/om-oss'} style={{textDecoration:"none", color:"white"}}><p style={{marginBottom: "20px", position: "relative", top: "20px", textDecoration: "none"}}>Om oss</p></Link>
                        <a style={{textDecoration:"none", color:"white"}}><p style={{marginBottom: "20px", position: "relative", top: "20px"}}>Prosjekter</p></a>
                        <Link to={'/kontakt'} style={{textDecoration:"none", color:"white"}}><p style={{marginBottom: "20px", position: "relative", top: "20px"}}>Kontakt</p></Link>
                    </div>
                </div>
                <div className='column' id='no-mobile'>
                    <h2>Skade pa bilen?</h2>
                    <Link to={"/pristilbud"}><button id='smaller-button'>Fa et pristilbud</button></Link>
                    <Link to={"/personvern"} style={{textDecoration:"none"}}><p style={{color:"white"}}>Personvern</p></Link>
                </div>
                <div className='column' id='no-mobile'>
                    <div className='right-footer' style={{width: "100%"}}>
                        <div id='container-row' style={{marginBottom: "10px", marginTop: "10px"}}>
                            <img src={location} style={{width: "13px", height: "16px", position: "relative", top: "10px", marginRight:"6px"}}></img>
                            <p className='p-small'>Cecilie Thoresens Vei 7, 1153 Oslo</p>
                        </div>
                        <div id='container-row' style={{marginBottom: "10px"}}>
                            <img src={mail}  style={{width: "20px", height: "16px", position: "relative", top: "10px", marginRight:"6px"}}></img>
                            <p className='p-small'>verksted@pdrpartner.no</p>
                        </div>
                        <div id='container-row' style={{marginBottom: "10px"}}>
                            <img src={call}  style={{width: "16x", height: "16px", position: "relative", top: "10px", marginRight:"6px"}}></img>
                            <p className='p-small'>(+47) 925 65 764</p>
                        </div>
                        <div id='container-row' style={{marginBottom: "10px"}}>
                            <img src={transportation}  style={{width: "16px", height: "16px", position: "relative", top: "10px", marginRight:"6px"}}></img>
                            <p className='p-small'>Man - Fre 7:00 - 17:00</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='footer-foot'>
                <img src={vector} style={{backgroundColor: "white", width:"100%"}}></img>
                <p style={{color: "white", fontSize: "9px"}}>©PDR Partner 2023 Created by Webstry</p>
                
            </section>
        </footer>
    );
}