import call from '../assets/desktop/call_b.png';
import transportation from '../assets/desktop/emoji_transportation_b.png';
import location from '../assets/desktop/location_on_b.png';
import mail from '../assets/desktop/mail_b.png';
import {Link } from "react-router-dom";
export default function Contact(){
    return (
        <div className="service-container">
            <div className="contact-left">
                <h1 className='ernest2' >Skade på bilen?</h1>
                <Link to={"/pristilbud"}><button className='black-button' id='ernest3' style={{marginTop: "20px"}}>Få et pristilbud</button></Link>
            </div>
            <div className="contact-right" >
                         <div className='shorter-row' id='container-row' style={{marginBottom: "50px", marginTop: "10px"}}>
                            <img src={location} id='ernest3' style={{width: "32px", height: "40px", position: "relative", top: "10px", marginRight:"10px"}}></img>
                            <p className='p-small' id='ernest' >Cecilie Thoresens Vei 7, 1153 Oslo</p>
                        </div>
                        <div className='shorter-row' id='container-row' style={{marginBottom: "50px"}}>
                            <img src={mail} id='ernest3'  style={{width: "50px", height: "40px", position: "relative", top: "10px", marginRight:"10px"}}></img>
                            <p className='p-small' id='ernest' >verksted@pdrpartner.no</p>
                        </div>
                        <div className='shorter-row' id='container-row' style={{marginBottom: "50px"}}>
                            <img src={call} id='ernest3'  style={{width: "40px", height: "40px", position: "relative", top: "10px", marginRight:"10px"}}></img>
                            <p className='p-small' id='ernest' >(+47) 925 65 764</p>
                        </div>
                        <div className='shorter-row' id='container-row' style={{marginBottom: "50px"}}>
                            <img src={transportation} id='ernest3'  style={{width: "40px", height: "40px", position: "relative", top: "10px", marginRight:"10px"}}></img>
                            <p className='p-small' id='ernest' >Man - Fre 7:00 - 17:00</p>
                        </div>
            </div>
        </div>
    );
}