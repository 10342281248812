import React, { useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import Field from "./field";
import ProductPhotosField from "./ProductPhotosField";
import imageCompression from 'browser-image-compression';

const Form = () => {

    const [regnummer, setRegnummer] = useState("");
    const [error_regnummer, setErrorRegnummer] = useState("")

    const [phone, setPhone] = useState("");
    const [error_phone, setErrorPhone] = useState("");

    const [email, setEmail] = useState("");
    const [error_email, setErrorEmail] = useState("");

    const [message, setMessage] = useState("");
    const [error_message, setErrorMessage] = useState("");

    const [productPhotos, setProductPhotos] = useState([]);

    const [image, setImage] = useState("./images/stage1.png");
    const [loading, setLoading] = useState(false)
    const controls = useAnimationControls();

    const nesteSide = (e) => {
        e.preventDefault();
        controls.start({
            right: "0%"
        });
        setImage("./images/stage1.png");
    };

    const nesteSide1 = (e) => {
        e.preventDefault();
        if (regnummer != "") {
            controls.start({
                right: "20%"
            });
            setErrorRegnummer("")
            setImage("./images/stage2.png");
        } else {
           setErrorRegnummer("Skriv inn reg. nummeret her.")
        }
    };

    const nesteSide2 = (e) => {
        e.preventDefault();
        if (phone != "") {
            controls.start({
                right: "40%"
            });
            setErrorPhone("")
            setImage("./images/stage3.png");
        } else {
            setErrorPhone("Skriv inn tlf. nummeret her.")
        }
    };

    const nesteSide3 = (e) => {
        e.preventDefault();
        if (email != "") {
            controls.start({
                right: "60%"
            });
            setErrorEmail("")
            setImage("./images/stage4.png");
        } else {
            setErrorEmail("Skriv inn e-post adresse her.")
        }
    };

    const nesteSide4 = (e) => {
        e.preventDefault(); 
        if (message != "") {
            controls.start({
                right: "80%"
            });
            setErrorMessage("")
            setImage("./images/stage5.png");
        } else {
            setErrorMessage("Skriv en kort oppsummering.")
        }
    };

    const changeValue = (field, value) => {
        switch (field) {
            case "regnummer":
                setRegnummer(value);
                break;
            case "phone":
                setPhone(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "message":
                setMessage(value);
                break;
                case "ProductPhotos":
                    setProductPhotos(prevPhotos => [...prevPhotos, ...value]);
                    break;
            default:
                break;
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
        setLoading(true)
        try {
            const data = {
                reg_number: regnummer,
                phone: phone,
                email: email,
                description: message,
                pictures: []
            };
    
            
            const uploadPromises = productPhotos.map(async (picture) => {
                try {
                    // Compression options
                    const options = {
                        maxSizeMB: 1,          // Target 1MB or less
                        maxWidthOrHeight: 1920, // Optionally resize to maintain quality and reduce file size
                        useWebWorker: true      // Use web worker for faster processing
                    };
            
                    // Compress the image
                    const compressedBlob = await imageCompression(picture, options);
            
                    // Preserve the original filename and extension
                    const fileName = picture.name; // Keep the original file name
                    const compressedFile = new File([compressedBlob], fileName, { type: picture.type });
            
                    const formData = new FormData();
                    formData.append('_payload', '{}');
                    formData.append('file', compressedFile);
            
                    const response = await fetch("https://admin.pdrpartner.no/api/media?depth=0&fallback-locale=null", {
                        method: "POST",
                        headers: {
                            "accept": "*/*",
                            "accept-language": "en",
                        },
                        body: formData
                    });
            
                    if (response.ok) {
                        const result = await response.json();
                        return {
                            filename: result.doc.filename,
                            path: `https://admin.pdrpartner.no${result.doc.url}`
                        };
                    } else {
                        console.error('Error:', response.status, response.statusText);
                        return null; // or handle error as needed
                    }
                } catch (error) {
                    console.error('Compression error:', error);
                    return null; // or handle error as needed
                }
            });
    
            // Wait for all image uploads to complete
            const images = await Promise.all(uploadPromises);
    
            // Filter out any null results in case some uploads failed
            data.pictures = images.filter(image => image !== null);
    
            console.log("Data", JSON.stringify(data));
    
            // Now submit the form with the updated data object
            const req = await fetch('https://admin.pdrpartner.no/api/form', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
    
            if (req.ok) {
                console.log('Form submitted successfully');

                window.location.href = "/takk-for-henvendelsen";
            } else {
                console.error('Error:', req.status, req.statusText);
            }
    
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };
    

    return (
        <form onSubmit={onSubmit} noValidate>
            {!loading?
                <div className="transparent-background">
                <div className="progress-background">
                    <img className="progress-bar" src={image} alt="Progress" />
                </div>
                <motion.div animate={controls} className="inside-background">
                    <div className="sliding-column">
                        <h1 className="form-h1">Reg. nummer</h1>
                        <Field
                            text="regnummer"
                            default="f.eks RB 55023"
                            name="regnummer"
                            value={regnummer}
                            onChange={(e) => changeValue('regnummer', e.target.value)}
                        />
                      <p>{error_regnummer}</p>
                        <button className="black-button" style={{ marginTop: "25px" }} onClick={nesteSide1}>
                            Neste
                        </button>
                    </div>
                    <div className="sliding-column">
                        <h1 className="form-h1">Tlf. nummer</h1>
                        <Field
                            text="phone"
                            default="Telefon nummer"
                            name="phone"
                            value={phone}
                            onChange={(e) => changeValue('phone', e.target.value)}
                        />
                        <div className="buttons-form">
                        <p>{error_phone}</p>
                            <button className="black-button" id="tilbake-button" style={{ marginTop: "25px" }} onClick={nesteSide}>
                                Tilbake
                            </button>
                            <button className="black-button" onClick={nesteSide2}>
                                Neste
                            </button>
                        </div>
                    </div>
                    <div className="sliding-column">
                        <h1 className="form-h1">Email</h1>
                        <Field
                            text="email"
                            default="Email"
                            name="email"
                            value={email}
                            onChange={(e) => changeValue('email', e.target.value)}
                        />
                        <div className="">
                        <p>{error_email}</p>
                            <button className="black-button" style={{ marginTop: "25px" }} id="tilbake-button" onClick={nesteSide1}>
                                Tilbake
                            </button>
                            <button className="black-button" onClick={nesteSide3}>
                                Neste
                            </button>
                        </div>
                    </div>
                    <div className="sliding-column">
                        <h1 className="form-h1">Fortell kort om skaden</h1>
                        <textarea
                            className="input"
                            id="TextArea"
                            placeholder="Hvordan kan vi hjelpe deg?"
                            text="message"
                            default="Hvordan kan vi hjelpe?"
                            name="message"
                            style={{padding:"15px"}}
                            value={message}
                            onChange={(e) => changeValue('message', e.target.value)}
                        />
                        <div>
                        <p>{error_message}</p>
                            <button className="black-button" id="tilbake-button" style={{ marginTop: "25px" }} onClick={nesteSide2}>
                                Tilbake
                            </button>
                            <button className="black-button" style={{ marginTop: "50px" }} onClick={nesteSide4}>
                                Neste
                            </button>
                        </div>
                    </div>
                    <div className="sliding-column" id="upload-column">
                        <h1 className="form-h1">Send bilder av skaden</h1>
                        <ProductPhotosField
                            onChange={(files) => changeValue('ProductPhotos', files)}
                            value={productPhotos}
                        />
                        <button className="black-button" id="tilbake-button" onClick={nesteSide3}>
                            Tilbake
                        </button>
                        <button type="submit" className="black-button">
                            Send
                        </button>
                    </div>
                </motion.div>
            </div>:
                <div className="transparent-background">
                    <div class="loader"></div>
                </div>
                
            }
            
        </form>
    );
};

export default Form;
